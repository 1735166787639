<template>
  <div>
    <view-title v-if="label">
      {{ label }}
    </view-title>

    <v-row v-if="row">
      <v-col
        v-for="(item, index) in value"
        :key="item[uniqueKey] || item[$options.UNIQUE_KEY] || index"
        cols="12"
        md="6"
      >
        <view-item
          v-for="child in children"
          :key="child.prop"
          class="mt-4"
          v-bind="child"
          :prop-path="getPropertyPath(child, index)"
        />
      </v-col>
    </v-row>
    <div v-for="(item, index) in value" v-else :key="item[uniqueKey] || item[$options.UNIQUE_KEY] || index">
      <h3 v-if="subTitle" class="mt-8">
        {{ $t(subTitle) }} <span v-if="hasCounter"> {{ index + 1 }} </span>
      </h3>
      <view-item
        v-for="child in children"
        :key="child.prop"
        class="mt-4"
        v-bind="child"
        :prop-path="getPropertyPath(child, index)"
      />
    </div>
  </div>
</template>

<script>
import { LIST } from '@/constants/viewTypes';
import { UNIQUE_KEY } from '@/schemas/config';
import ViewTitle from './ViewTitle.vue';

export default {
  name: 'SchemaViewFieldList',

  components: {
    ViewTitle,
    ViewItem: () => import('./ViewItem.vue'),
  },

  inheritAttrs: false,

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: LIST.default,
    },
    subTitle: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasCounter() {
      return this.viewType === LIST.counter;
    },
  },

  methods: {
    getPropertyPath(child, index) {
      return child.prop ? [...this.propPath, index, child.prop] : [...this.propPath, index];
    },
  },

  UNIQUE_KEY,
};
</script>
