<template>
  <div>
    <entity-header
      class="mb-10"
      :title="employeeFullName"
      :is-archived="isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdate"
      :loading="isLoading"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schema" :data="employee" />
  </div>
</template>

<script>
// Models
import { schema } from '@/schemas/employee.view.schema';

// Utils
import { getCredentialsFromSections } from '@/utils/employees';

// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

// Constants
import { EMPLOYEES_EDIT, EMPLOYEES_ARCHIVE, EMPLOYEES } from '@/constants/routes';
import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';
import { EMPLOYEE, ARCHIVE_EMPLOYEE, UNARCHIVE_EMPLOYEE } from '@/constants/analyticsActions';
import { EMPLOYEES as EMPLOYEES_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

export default {
  name: 'EmployeeDetailed',

  components: { EntityHeader, ViewBuilder },

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: false,
      employee: {},
    };
  },

  computed: {
    canUpdate() {
      return this.$can(UPDATE, EMPLOYEES_SUBJECT);
    },

    isArchived() {
      return this.employee.status === 'archived';
    },
    backRoute() {
      if (this.$route.params.prevPageRoute) return this.$route.params.prevPageRoute;

      const routeName = this.employee.isArchived ? EMPLOYEES_ARCHIVE : EMPLOYEES;

      return { name: routeName, query: { page: this.prevPage || 1 } };
    },

    employeeFullName() {
      if (this.isLoading) return '';
      return `${this.employee.firstName} ${this.employee.lastName}`;
    },

    editRoute() {
      return { name: EMPLOYEES_EDIT, params: { id: this.id } };
    },
  },

  async mounted() {
    analyticsService.track(EMPLOYEE);
    this.isLoading = true;
    try {
      await this.initDetail();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async archive() {
      analyticsService.track(this.isArchived ? UNARCHIVE_EMPLOYEE : ARCHIVE_EMPLOYEE);
      const options = { isArchived: !this.isArchived, employees: [this.id] };
      try {
        this.isLoading = true;
        await usersService.archiveEmployees(options);
        await this.initDetail();
      } finally {
        this.isLoading = false;
      }
    },

    getCredentialsFromSections,

    async initDetail() {
      const employee = await usersService.getEmployeeById(this.id);
      this.employee = this.normalizeData(employee);
    },

    normalizeData(data) {
      const newEmployee = { ...data };
      newEmployee.sections = this.getCredentialsFromSections(data);
      newEmployee.roles = data.roles.map(role => ({
        id: role.id,
        name: role.name,
        projects: {
          all: role.isAllProjects,
          include: role.projects,
        },
      }));
      return newEmployee;
    },
  },

  schema,
};
</script>
