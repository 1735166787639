<template>
  <component
    :is="component"
    v-bind="{ ...$props, ...$attrs }"
    :label="translateLabel"
    :payload="payloadParams"
    :value="getPropertyValue({ propPath })"
    :prop-path="propPath"
  />
</template>

<script>
import * as schemaItemTypes from '@/schemas/schemaItemTypes';
import { PATH_POINTERS } from '@/constants/builder';

import SERIALIZATIONS_MAP from '@/constants/serializersMap';

import ViewFieldString from './ViewFieldString.vue';
import ViewFieldNumber from './ViewFieldNumber.vue';
import ViewFieldAmount from './ViewFieldAmount.vue';
import ViewFieldLink from './ViewFieldLink.vue';
import ViewFieldEnum from './ViewFieldEnum.vue';
import ViewFieldProject from './ViewFieldProject.vue';
import ViewFieldBoolean from './ViewFieldBoolean.vue';
import ViewTitledSection from './ViewTitledSection.vue';
import ViewRow from './ViewRow.vue';
import ViewFlexRow from './ViewFlexRow.vue';
import ViewFieldSquare from './ViewFieldSquare.vue';
import ViewFieldList from './ViewFieldList.vue';
import ViewFieldImageList from './ViewFieldImageList.vue';
import ViewFieldFileList from './ViewFieldFileList.vue';
import ViewFieldUnit from './ViewFieldUnit.vue';
import ViewFieldRoom from './ViewFieldRoom.vue';
import ViewFIeldDate from './ViewFIeldDate.vue';
import ViewFieldCountry from './ViewFieldCountry.vue';
import ViewTitledSectionWithStatus from './ViewTitledSectionWithStatus.vue';
import ViewFieldClient from './ViewFieldClient.vue';
import ViewFieldUnitSalesHistory from './ViewFieldUnitSalesHistory.vue';
import ViewFieldActivities from './ViewFieldActivities.vue';
import ViewExpansionSection from './ViewExpansionSection.vue';
import ViewFieldCredentials from './ViewFieldCredentials.vue';
import ViewExpansionPanel from './ViewExpansionPanel.vue';
import ViewManyProjects from './ViewManyProjects.vue';
import ViewManyItems from './ViewManyItems.vue';
import ViewRequestClientsType from './ViewRequestClientsType.vue';
import ViewRequestPayment from './ViewRequestPayment.vue';
import ViewTaskPayment from './ViewTaskPayment.vue';
import ViewFieldCoast from './ViewFieldCoast.vue';
import ViewCardSection from './ViewCardSection.vue';
import ViewTaskStatusChange from './ViewTaskStatusChange.vue';
import ViewFieldScript from './ViewFieldScript.vue';
import ViewFieldContracts from './ViewFieldContracts.vue';
import ViewFieldEmployeesRoles from './ViewFieldEmployeesRoles.vue';
import ViewFieldTeamList from './ViewFieldTeamList.vue';
import ViewFieldRequestIcon from './ViewFieldRequestIcon.vue';
import ViewFieldRequestFieldsList from './ViewFieldRequestFieldsList.vue';
import ViewManyRoles from './ViewManyRoles.vue';
import ViewTaskActivities from './ViewTaskActivities.vue';

const UNKNOWN_FIELD = {
  render: h => h('p', 'unknown field'),
};

const FORM_FIELD_MAP = {
  [schemaItemTypes.STRING]: ViewFieldString,
  [schemaItemTypes.NUMBER]: ViewFieldNumber,
  [schemaItemTypes.AMOUNT]: ViewFieldAmount,
  [schemaItemTypes.LINK]: ViewFieldLink,
  [schemaItemTypes.ENUM]: ViewFieldEnum,
  [schemaItemTypes.PROJECT]: ViewFieldProject,
  [schemaItemTypes.BUILDING]: ViewFieldProject,
  [schemaItemTypes.BOOLEAN]: ViewFieldBoolean,
  [schemaItemTypes.TITLED_SECTION]: ViewTitledSection,
  [schemaItemTypes.ROW]: ViewRow,
  [schemaItemTypes.FLEX_ROW]: ViewFlexRow,
  [schemaItemTypes.SQUARE]: ViewFieldSquare,
  [schemaItemTypes.LIST]: ViewFieldList,
  [schemaItemTypes.IMAGE_LIST]: ViewFieldImageList,
  [schemaItemTypes.FILE_LIST]: ViewFieldFileList,
  [schemaItemTypes.UNIT]: ViewFieldUnit,
  [schemaItemTypes.ROOM]: ViewFieldRoom,
  [schemaItemTypes.DATE]: ViewFIeldDate,
  [schemaItemTypes.COUNTRY]: ViewFieldCountry,
  [schemaItemTypes.CLIENT]: ViewFieldClient,
  [schemaItemTypes.TITLED_SECTION_WITH_STATUS]: ViewTitledSectionWithStatus,
  [schemaItemTypes.UNIT_SALES_HISTORY]: ViewFieldUnitSalesHistory,
  [schemaItemTypes.HISTORY]: ViewFieldActivities,
  [schemaItemTypes.EXPANSION_SECTION]: ViewExpansionSection,
  [schemaItemTypes.CREDENTIALS_VARIANT]: ViewFieldCredentials,
  [schemaItemTypes.MANY_PROJECTS]: ViewManyProjects,
  [schemaItemTypes.MANY_ITEMS]: ViewManyItems,
  [schemaItemTypes.MANY_ROLES]: ViewManyRoles,
  [schemaItemTypes.MANY_BUILDINGS]: ViewManyProjects,
  [schemaItemTypes.MANY_UNITS]: ViewManyProjects,
  [schemaItemTypes.MANY_ROOMS]: ViewManyProjects,
  [schemaItemTypes.REQUEST_CLIENTS_TYPE]: ViewRequestClientsType,
  [schemaItemTypes.REQUEST_PAYMENT]: ViewRequestPayment,
  [schemaItemTypes.TASK_PAYMENTS]: ViewTaskPayment,
  [schemaItemTypes.COAST]: ViewFieldCoast,
  [schemaItemTypes.CARD_SECTION]: ViewCardSection,
  [schemaItemTypes.TASK_STATUS_CHANGE]: ViewTaskStatusChange,
  [schemaItemTypes.SCRIPT_ITEM]: ViewFieldScript,
  [schemaItemTypes.EXPANSION_PANEL]: ViewExpansionPanel,
  [schemaItemTypes.CONTRACTS]: ViewFieldContracts,
  [schemaItemTypes.EMPLOYEES_ROLES]: ViewFieldEmployeesRoles,
  [schemaItemTypes.TEAM_LIST]: ViewFieldTeamList,
  [schemaItemTypes.REQUEST_ICON]: ViewFieldRequestIcon,
  [schemaItemTypes.REQUEST_FIELDS_LIST]: ViewFieldRequestFieldsList,
  [schemaItemTypes.ACTIVITIES]: ViewTaskActivities,
};

export default {
  name: 'SchemaViewItem',

  inheritAttrs: false,

  inject: {
    getPropertyValue: 'getPropertyValue',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    component() {
      return FORM_FIELD_MAP[this.type] || UNKNOWN_FIELD;
    },

    translateLabel() {
      if (this.payload.translate === false) {
        return this.label;
      }

      return this.$t(this.label);
    },

    payloadParams() {
      return this.payload.reduce((payload, { param, from, serializer }) => {
        const buildFromPath = this.buildPropPath(from);

        let value = this.getPropertyValue({ propPath: buildFromPath });

        if (serializer) {
          value = SERIALIZATIONS_MAP[serializer](value);
        }

        if (value !== undefined) {
          return { ...payload, [param]: value };
        }

        return payload;
      }, {});
    },
  },

  methods: {
    buildPropPath(propPaths) {
      return propPaths.map((propPath, index) => {
        if (propPath === PATH_POINTERS.currentIndex) {
          return this.propPath[index];
        }

        return propPath;
      });
    },
  },
};
</script>
