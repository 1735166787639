<template>
  <v-row>
    <v-col v-for="clientType in clentsTypes" :key="clientType.value" size="12" md="3">
      <view-field :label="$t(clientType.text)">
        <span v-if="isAllTypes || clientType.value === value">{{ $t('yes') }}</span>
        <span v-else>{{ $t('no') }}</span>
      </view-field>
    </v-col>
  </v-row>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewRequestClientsType',

  components: { ViewField },

  props: {
    value: { type: String, default: '' },
    label: { type: String, required: true },
  },

  data() {
    return {
      clentsTypes: [
        {
          text: 'client.tenant',
          value: 'tenant',
        },
        {
          text: 'client.owner',
          value: 'owner',
        },
      ],
    };
  },

  computed: {
    isAllTypes() {
      return this.value === 'all';
    },
  },
};
</script>
