<template>
  <view-field v-if="!value.isPaymentRequired" class="font-weight-medium" :label="$t('label.value')">
    {{ $t('no') }}
  </view-field>
  <v-row v-else>
    <v-col cols="12" md="3">
      <view-field class="font-weight-medium" :label="`${$t('label.amount')}, ${currencySymbol}`">
        {{ value.paymentAmount.value }} {{ currencySymbol }}
      </view-field>
    </v-col>
    <v-col cols="12" md="3">
      <view-field class="font-weight-medium" :label="$t('label.contractor')">
        <router-link :to="linkToContractor">
          {{ value.paymentContractor.name }}
        </router-link>
      </view-field>
    </v-col>
    <v-col v-if="value.paymentReceipt" cols="12" md="3">
      <view-image-list :label="$t('label.receipt')" :value="value.paymentReceipt" :only-one="true" />
    </v-col>
  </v-row>
</template>

<script>
import { CONTRACTORS_DETAILED } from '@/constants/routes';

import ViewField from '@/components/ViewField.vue';
import ViewImageList from '@/components/schema/ViewFieldImageList.vue';

export default {
  name: 'SchemaViewTaskPayment',

  components: { ViewField, ViewImageList },

  props: {
    value: { type: [Object, Boolean], default: false },
  },

  computed: {
    currencySymbol() {
      return this.value.paymentAmount.currencySymbol;
    },

    linkToContractor() {
      return {
        name: CONTRACTORS_DETAILED,
        params: {
          id: this.value.paymentContractor.id,
          prevPage: this.$route,
        },
      };
    },
  },

  // currency_symbol
};
</script>
