<template>
  <div class="schema-view-field-contact-container">
    <h3 class="text-subtitle-1 font-weight-bold" :class="{ 'primary--text': active }">{{ title }}</h3>

    <v-divider class="schema-view-field-contact-container__divider" />
    <slot />
    <v-divider class="schema-view-field-contact-container__divider" />

    <v-btn
      v-if="withPagination"
      secondary
      class="primary--text"
      elevation="0"
      color="secondary"
      :block="isBlockButton"
      :loading="loading"
      @click="showMore"
    >
      {{ $t('unit.contract_show_more') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ViewFieldContractContainer',

  props: {
    title: { type: String, required: true },
    withPagination: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },

  computed: {
    isBlockButton() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    showMore() {
      this.$emit('show-more');
    },
  },
};
</script>

<style lang="scss">
.schema-view-field-contact-container {
  &__divider {
    border-color: rgba($--blue-color-20, 0.1) !important;
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
  }
}
</style>
