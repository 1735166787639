<template>
  <div>
    <div v-if="media.isMobile" class="mb-4">
      <div class="d-flex justify-space-between">
        <p class="font-weight-medium mb-1">{{ author }}</p>
        <span class="grey--text">{{ date }}</span>
      </div>
      <div>
        <span class="grey--text">{{ message }}</span>
      </div>
    </div>
    <div v-else class="d-flex mb-3">
      <p class="font-weight-medium mb-1 me-1">{{ author }}</p>
      <span class="grey--text"> {{ message }}</span>
      <v-icon>mdi-circle-small</v-icon>
      <span class="grey--text">{{ date }}</span>
    </div>

    <div v-if="isStatus">
      <p class="d-flex align-center mb-3">
        <span>{{ $t(`tasks.${activity.data.oldStatus}`) }}</span>
        <arrow-right class="mx-2" width="24px" height="24px" fill="#1E1E22" />
        <span>{{ $t(`tasks.${activity.data.newStatus}`) }}</span>
      </p>
    </div>
    <div v-if="isComment">
      <div class="mb-3">
        <p>{{ activity.data.text }}</p>
        <view-field-file-list :value="activity.files" show-row />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { translateDate } from '@/utils/dateFormatting';
import { format } from 'date-fns';

// Icons
import ArrowRight from '@/assets/icons/ArrowRight.svg';

// Components
import ViewFieldFileList from '@/components/schema/ViewFieldFileList.vue';

export default {
  name: 'ActivitiesViewItem',

  inject: ['media'],

  components: {
    ArrowRight,
    ViewFieldFileList,
  },

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isStatus() {
      return this.activity.action === 'status';
    },
    isComment() {
      return this.activity.action === 'comment';
    },
    isCreate() {
      return this.activity.action === 'create';
    },
    author() {
      return this.activity.author ? `${this.activity.author.firstName} ${this.activity.author.lastName}` : '-';
    },
    date() {
      return this.formatDateWithTime(this.activity.createdAt);
    },
    message() {
      if (this.isComment) {
        return this.$t('task.posted_comment');
      }

      if (this.isCreate) {
        return this.$t('task.created_task');
      }

      return this.$t('task.change_status_history');
    },
  },

  methods: {
    formatDateWithTime(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L kk:mm'));
      }
      return date;
    },
  },
};
</script>
