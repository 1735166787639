<template>
  <view-field class="font-weight-medium" :label="label">
    {{ currentValue }}
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldProject',

  components: { ViewField },

  props: {
    value: { type: Object, default: () => ({}) },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      return this.value?.name || '—';
    },
  },
};
</script>
