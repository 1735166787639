<template>
  <view-field class="font-weight-medium" :label="label">
    {{ translatedDate }}
  </view-field>
</template>

<script>
// node_modules
import format from 'date-fns/format';

// Components
import ViewField from '@/components/ViewField.vue';

// Utils
import { translateDate } from '@/utils/dateFormatting';

export default {
  name: 'SchemaViewFieldDate',

  components: { ViewField },

  props: {
    value: { type: [String, Array], default: '' },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      return this.value || '—';
    },

    translatedDate() {
      const splitValue = this?.value?.split?.(' - ');
      if (Array.isArray(this.value) || splitValue?.length > 1) {
        const dates = splitValue?.length > 1 ? splitValue : this.value;

        return dates
          .map(date => {
            return translateDate(this.formatDate(date));
          })
          .join(' - ');
      }

      return translateDate(this.formatDate(this.currentValue));
    },
  },

  methods: {
    formatDate(date) {
      if (this.currentValue === '—') {
        return '—';
      }
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'd L yyyy');
    },
  },
};
</script>
