class ViewBuilderService {
  /**
   * @type {Map<string, Set<(...unknown) => unknown>>}
   */
  listenersContainer = new Map();

  /**
   * @param {string} type
   * @param {(...unknown) => unknown} listener
   */
  subscribe(type, listener) {
    this.initListenerContainer(type);
    this.listenersContainer.get(type).add(listener);

    return () => {
      this.listenersContainer.get(type).delete(listener);
    };
  }

  /**
   * @param {string} type
   * @param {unknown[]} params
   */
  notify(type, params = []) {
    this.listenersContainer.get(type).forEach(listener => listener(...params));
  }

  initListenerContainer(type) {
    const hasContainer = this.listenersContainer.has(type);

    if (!hasContainer) {
      this.listenersContainer.set(type, new Set());
    }
  }
}

export default new ViewBuilderService();
