<template>
  <view-titled-section v-bind="$attrs">
    <template #label>
      <span class="text-h6 font-weight-medium mt-4 mb-4">
        {{ label }} <span class="text-h6 font-weight-regular text--secondary">{{ payload.status }}</span>
      </span>
    </template>
  </view-titled-section>
</template>

<script>
import ViewTitledSection from '@/components/schema/ViewTitledSection.vue';

export default {
  name: 'ViewTitledSectionWithStatus',

  components: { ViewTitledSection },

  props: {
    label: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
};
</script>
