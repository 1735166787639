<template>
  <div class="entity-header d-flex flex-row justify-space-between">
    <div class="d-flex flex-row align-start align-md-center mr-0 mr-md-5">
      <icon-button class="mr-6" :to="backRoute">
        <v-icon>mdi-arrow-left</v-icon>
      </icon-button>

      <div v-if="!loading" class="d-flex flex-column flex-md-row align-md-center">
        <h1
          class="entity-header__title text-body-1 text-md-h4 font-weight-bold"
          :class="{ 'text--secondary': isArchived }"
        >
          {{ title }}
        </h1>
        <slot name="status">
          <span
            class="ml-0 ml-md-4 mt-0 mt-md-2 primary--text text-caption text-md-h5"
            :class="{ 'entity-header__secondary': isArchived }"
          >
            <template v-if="isArchived">
              {{ $t('entity.archive') }}
            </template>

            <template v-else>
              {{ $t('entity.active') }}
            </template>
          </span>
        </slot>
      </div>
    </div>

    <div v-if="(canUpdate || $scopedSlots.actions) && !loading" class="d-flex">
      <slot name="actions">
        <icon-button v-if="canDisplayMobileEditButton" class="mr-2" color="primary" :to="editRoute">
          <v-icon>mdi-pencil</v-icon>
        </icon-button>
        <v-btn v-else-if="canDisplayEditButton" color="primary" large class="mr-4" :to="editRoute">
          {{ $t('button.edit') }}
          <v-icon right>mdi-pencil</v-icon>
        </v-btn>

        <icon-button v-if="media.isMobile && isArchived" color="secondary" class="primary--text" @click="archive">
          <v-icon>mdi-archive-arrow-up</v-icon>
        </icon-button>
        <v-btn v-else-if="isArchived" color="secondary" class="primary--text" large @click="archive">
          {{ $t('button.unarchive') }}
          <v-icon right>mdi-archive-arrow-up</v-icon>
        </v-btn>

        <icon-button v-else-if="media.isMobile" color="secondary" class="primary--text" @click="archive">
          <v-icon>mdi-archive-arrow-down</v-icon>
        </icon-button>
        <v-btn v-else color="secondary" class="primary--text" large @click="archive">
          {{ $t('button.archive') }}
          <v-icon right>mdi-archive-arrow-down</v-icon>
        </v-btn>
      </slot>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'EntityHeader',

  components: { IconButton },

  inject: ['media'],

  props: {
    title: { type: String, default: '' },
    backRoute: { type: Object, default: () => ({}) },
    editRoute: { type: Object, default: () => ({}) },
    isArchived: { type: Boolean, default: false },
    canUpdate: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  computed: {
    canDisplayEditButton() {
      return !this.isArchived;
    },

    canDisplayMobileEditButton() {
      return !this.isArchived && this.media.isMobile;
    },
  },

  methods: {
    archive() {
      this.$emit('archive');
    },
  },
};
</script>

<style lang="scss">
.entity-header {
  &__secondary {
    opacity: 0.5;
  }

  &__title {
    word-break: break-all;
  }
}
</style>
