<template>
  <v-row class="employees-roles">
    <v-col v-for="role in value" :key="role.id" cols="12" md="6">
      <view-field class="font-weight-medium" :label="role.name">
        <employees-chip-group :role-name="role.name" :employees="role.employees" />
      </view-field>
    </v-col>
  </v-row>
</template>

<script>
// Components
import ViewField from '@/components/ViewField.vue';
import EmployeesChipGroup from '@/components/EmployeesChipGroup.vue';

export default {
  name: 'SchemaViewFieldTeamList',

  components: { ViewField, EmployeesChipGroup },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.employees-roles {
  width: 100%;
  max-width: 670px;
}
</style>
