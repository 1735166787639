import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const dependentProperties = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  PROJECTS_NAMES: 'projectsNames',
  SECTIONS: 'sections',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'employee.personal_information',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'employee.phone',
            prop: dependentProperties.PHONE,
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.STRING,
            label: 'employee.email',
            prop: dependentProperties.EMAIL,
            rules: [rules.REQUIRED],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'employee.roles',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.EMPLOYEES_ROLES,
            prop: 'roles',
            rules: [rules.REQUIRED],
            size: 12,
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.CREDENTIALS_VARIANT,
    label: 'employee.credentials',
    prop: dependentProperties.SECTIONS,
  },
];

export { schema as default };
