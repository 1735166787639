<template>
  <view-field class="font-weight-medium">
    <template #label> {{ label }} {{ $t(areaUnit) }}<sup>2</sup></template>
    {{ value }}
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

const AREA_UNIT_NAME = {
  m: 'si.m',
  ft: 'si.ft',
};

export default {
  name: 'SchemaViewFieldSquare',

  components: { ViewField },

  props: {
    value: { type: Number, default: 0 },
    label: { type: String, required: true },
    payload: { type: Object, default: () => ({}) },
  },
  computed: {
    areaUnit() {
      return AREA_UNIT_NAME[this.payload?.areaUnit] || 'si.m';
    },
  },
};
</script>
