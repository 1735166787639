<template>
  <view-field class="font-weight-medium" :label="label">
    {{ joinWithLimit(value) }}
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

import joinWithLimit from '@/utils/joinWithLimit';

export default {
  name: 'SchemaViewFieldManyItems',

  components: { ViewField },

  props: {
    value: { type: Array, default: () => [] },
    label: { type: String, required: true },
  },

  methods: {
    joinWithLimit,
  },
};
</script>
