<template>
  <v-dialog v-if="projects.all" v-model="modalShowAlProjects" scrollable max-width="800px">
    <template #activator="{ on, attrs }">
      <v-chip-group active-class="" column multiple>
        <v-chip v-bind="attrs" v-on="on">
          {{ $t('label.all_projects') }}
        </v-chip>
      </v-chip-group>
    </template>
    <v-card>
      <v-card-title class="justify-space-between">
        <h1>{{ roleName }}</h1>
        <v-btn icon color="primary" @click="modalShowAlProjects = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-chip-group active-class="" column multiple>
          <v-chip v-for="project in projects.include" :key="project.id" :to="getProjectRoute(project)">
            {{ project.name }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-chip-group v-else active-class="" column multiple>
    <v-chip v-for="project in slicesProjects" :key="project.id" :to="getProjectRoute(project)">
      {{ project.name }}
    </v-chip>
    <v-dialog v-model="modalShowAlProjects" scrollable max-width="800px">
      <template #activator="{ on, attrs }">
        <v-chip v-if="moreCountProjects" v-bind="attrs" v-on="on"> +{{ moreCountProjects }} </v-chip>
      </template>
      <v-card>
        <v-card-title class="justify-space-between">
          <h1>{{ roleName }}</h1>
          <v-btn icon color="primary" @click="modalShowAlProjects = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-chip-group active-class="" column multiple>
            <v-chip v-for="project in projects.include" :key="project.id" :to="getProjectRoute(project)">
              {{ project.name }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-chip-group>
</template>

<script>
import { EMPLOYEES_DETAILED, PROJECTS_DETAILED } from '@/constants/routes';

export default {
  name: 'ProjectsChipGroup',

  props: {
    roleName: {
      type: String,
      required: true,
    },
    projects: {
      type: Object,
      required: true,
    },
    projectsLimit: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      modalShowAlProjects: false,
    };
  },

  computed: {
    slicesProjects() {
      if (this.projects.all) return null;

      if (this.projects.include.length > this.projectsLimit) {
        return [...this.projects.include.slice(0, this.projectsLimit)];
      }
      return this.projects.include;
    },

    moreCountProjects() {
      return this.projects.include.length > this.projectsLimit && !this.projects.all
        ? this.projects.include.length - this.projectsLimit
        : null;
    },
  },

  methods: {
    getProjectRoute(project) {
      return {
        name: PROJECTS_DETAILED,
        params: { id: project.id, prevPageRoute: { name: EMPLOYEES_DETAILED, params: { id: this.$route.params.id } } },
      };
    },
  },
};
</script>

<style scoped>
.v-chip {
  background: #e6ebff !important;
}
</style>
