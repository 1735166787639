<template>
  <div>
    <div v-for="(field, index) in value" :key="field.position">
      <v-divider v-if="index" class="schema-view-field-contact-container__divider" />

      <v-row>
        <v-col cols="12" md="6">
          <view-field class="font-weight-medium" :label="$t('label.name')">
            {{ field.name }}
          </view-field>
        </v-col>
        <v-col cols="12" md="6">
          <view-field class="font-weight-medium" :label="$t('label.type')">
            {{ $t(`requests.field_${field.fieldType}`) }}<span v-if="field.fieldType === 'rowadder'">:</span>
            <template v-if="field.fieldType === 'rowadder'">
              <span v-for="(option, optionIndex) in field.fieldOptions.columns" :key="optionIndex">
                {{ option.title }}<template v-if="optionIndex !== field.fieldOptions.columns.length - 1">,</template>
              </span>
            </template>
          </view-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldRequestFieldsList',

  components: { ViewField },

  props: {
    value: { type: Array, required: true },
  },
};
</script>
