<template>
  <h2 class="text-h5 font-weight-bold mb-8" :class="removeMT ? 'mt-0' : 'mt-14'">
    <slot>{{ label }}</slot>
  </h2>
</template>

<script>
export default {
  name: 'SchemaViewTitle',

  props: {
    label: { type: String, default: '' },
    removeMT: { type: Boolean, default: false },
  },
};
</script>
