<template>
  <div>
    <div v-for="(item, index) in value" :key="index" class="mb-10">
      <p class="mb-3">
        <span class="font-weight-medium">{{ item.author }}</span>
        <span class="grey--text"> {{ $t('task.change_status_history') }}</span>
        <v-icon>mdi-circle-small</v-icon>
        <span class="grey--text">{{ formatDateWithTime(item.createdAt) }}</span>
      </p>
      <p class="d-flex align-center mb-3">
        <span>{{ $t(`tasks.${item.oldStatus}`) }}</span>
        <v-icon class="mx-2">mdi-arrow-right</v-icon>
        <span>{{ $t(`tasks.${item.newStatus}`) }}</span>
      </p>
    </div>
  </div>
</template>

<script>
// Node_modules
import { format } from 'date-fns';

// Utils
import { translateDate } from '@/utils/dateFormatting';

// Components

export default {
  name: 'ViewHistory',

  inject: ['media'],

  props: {
    value: { type: Array, required: true },
    label: { type: String, required: true },
    payload: { type: Object, required: true },
  },

  methods: {
    formatDateWithTime(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L kk:mm'));
      }
      return date;
    },
  },
};
</script>
