<template>
  <div class="schema-image-item rounded">
    <button type="button" @click="click">
      <v-img :src="imageUrl" width="154" height="154" />
    </button>
    <div class="schema-image-item__overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'SchemaViewImageItem',

  props: {
    imageUrl: { type: String, required: true },
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.schema-image-item {
  width: 154px;
  height: 154px;
  overflow: hidden;
  position: relative;
  border: none;
  cursor: pointer;
  flex-shrink: 0;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#1e1e22, 0.5);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &:hover > &__overlay {
    opacity: 1;
  }
}
</style>
