import { render, staticRenderFns } from "./ViewFieldNumber.vue?vue&type=template&id=f78a6ac4"
import script from "./ViewFieldNumber.vue?vue&type=script&lang=js"
export * from "./ViewFieldNumber.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports