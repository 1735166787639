<template>
  <svg width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.59 15.42 12 10.83l-4.59 4.59L6 14l6-6 6 6-1.41 1.42Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconArrowUp',
};
</script>
