<template>
  <div class="view-flex-row">
    <div
      v-for="item in filteredChildrens"
      :key="item.prop"
      class="view-flex-row__item"
      :class="`view-flex-row__item-${item.prop || item.type}`"
    >
      <view-item v-bind="item" :prop-path="getPropertyPath(item)">
        {{ item }}
      </view-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewFlexRow',

  components: {
    ViewItem: () => import('./ViewItem.vue'), // to register the component correctly
  },

  inheritAttrs: false,

  inject: {
    mustDisplaySchemaItem: 'mustDisplaySchemaItem',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredChildrens() {
      return this.children.filter(item => this.mustDisplaySchemaItem(item, [...this.propPath, item.prop]));
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>

<style lang="scss">
.view-flex-row {
  display: flex;
  width: 100%;
  align-items: baseline;
  &__item {
    flex-grow: 1;
  }
}
</style>
