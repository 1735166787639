<template>
  <div class="view-field-script">
    <v-row>
      <v-col cols="12" :md="3" :xl="3">
        <view-field-string :label="labelName" :value="value.name" />
      </v-col>
      <v-col>
        <view-field v-if="isLinkType" class="font-weight-medium" :label="$t('label.link')">
          <a class="view-field-script__link" :href="value.action" target="_blank">
            {{ value.action }}
          </a>
        </view-field>
        <view-expansion-panel v-else-if="isMessagesType" :value="actionMessage" :label="$t('label.text')" text />
        <view-field-file-list v-else-if="isFileType" :value="actionFile" />
        <view-field-string v-else :label="$t('label.value')" :value="value.action" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TYPE_SCRIPTS from '@/constants/servisesTypeScript';
import ViewField from '@/components/ViewField.vue';
import ViewFieldString from './ViewFieldString.vue';
import ViewExpansionPanel from './ViewExpansionPanel.vue';
import ViewFieldFileList from './ViewFieldFileList.vue';

export default {
  name: 'ViewFieldScript',
  components: {
    ViewFieldString,
    ViewExpansionPanel,
    ViewField,
    ViewFieldFileList,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    labelName() {
      switch (this.value.type) {
        case TYPE_SCRIPTS.LINK:
          return this.$t('label.link_name');
        case TYPE_SCRIPTS.FILE:
          return this.$t('label.file_name');
        case TYPE_SCRIPTS.MESSAGE:
          return this.$t('label.message_title');
        default:
          return this.$t('label.title');
      }
    },
    actionMessage() {
      return this.value.action.split(' ');
    },
    actionFile() {
      return [{ id: 1, url: this.value.action }];
    },
    isLinkType() {
      return this.value.type === TYPE_SCRIPTS.LINK;
    },
    isFileType() {
      return this.value.type === TYPE_SCRIPTS.FILE;
    },
    isMessagesType() {
      return this.value.type === TYPE_SCRIPTS.MESSAGE;
    },
  },
};
</script>

<style lang="scss">
.view-field-script {
  &__link {
    text-decoration: none;
    color: $--blue-color-20 !important;
  }
}
</style>
