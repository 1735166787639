/* eslint-disable class-methods-use-this */
import client from '@/http/client';
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/issues';

class IssueService {
  getIssue({ requestBody, params, config }) {
    return client.post(`${BASE_URL}/select/issues/`, requestBody, { ...config, params });
  }

  getRecurrenceIssue({ params, config }) {
    return client.get(`${BASE_URL}/recurring/`, {
      ...config,
      params,
    });
  }

  getIssueById(issueId) {
    return client.get(`${BASE_URL}/for_employees/${issueId}/`);
  }

  getRecurrenceIssueById(issueId) {
    return client.get(`${BASE_URL}/recurring/${issueId}/`);
  }

  changeIssueStatus({ issueId, comment, newStatus, isShowDetailsToClient, files }) {
    return client.patch(`${BASE_URL}/for_employees/${issueId}/change_status/`, {
      comment,
      newStatus,
      isShowDetailsToClient,
      files,
    });
  }

  createIssue(bodyRequest) {
    return client.post(`${BASE_URL}/for_employees/`, { ...bodyRequest });
  }

  createRequestIssue(bodyRequest) {
    return client.post(`${BASE_URL}/for_employees/from_request/`, { ...bodyRequest });
  }

  updateRequestIssueById(bodyRequest, taskId) {
    return client.patch(`${BASE_URL}/for_employees/${taskId}/from_request/`, { ...bodyRequest });
  }

  createRecurrenceIssue(bodyRequest) {
    return client.post(`${BASE_URL}/recurring/`, { ...bodyRequest });
  }

  updateIssueById(bodyRequest, taskId) {
    return client.patch(`${BASE_URL}/for_employees/${taskId}/`, { ...bodyRequest });
  }

  updateRecurrenceIssueById(bodyRequest, taskId) {
    return client.patch(`${BASE_URL}/recurring/${taskId}/`, { ...bodyRequest });
  }

  deleteIssueById(issueId) {
    return client.delete(`${BASE_URL}/for_employees/${issueId}/`);
  }

  deleteRecurrenceIssueById(issueId) {
    return client.delete(`${BASE_URL}/recurring/${issueId}/`);
  }

  exportIssue({ status, forMe, projects, buildings, units, clients, employees } = {}, config) {
    return client.postRaw(
      `${BASE_URL}/for_employees/xlsx/`,
      {
        buildings,
        units,
        projects,
        clients,
        employees,
      },
      {
        ...config,
        params: {
          status,
          forMe,
        },
        responseType: 'blob',
      }
    );
  }

  async downloadTasks(filter, fallbackFilename) {
    const response = await this.exportIssue(filter);

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  addComment({ comment, files, isShowDetailsToClient = false, issueId }) {
    return client.post(`${BASE_URL}/for_employees/${issueId}/comment/`, { comment, files, isShowDetailsToClient });
  }

  getActivities(params) {
    return client.get(`${BASE_URL}/activities/`, { params });
  }
}

export default new IssueService();
