<template>
  <v-expansion-panels v-if="media.isMobile" class="view-title" flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="view-title__header">
        <slot name="label">
          <span class="text-h6 font-weight-medium mt-4 mb-4">{{ label }}</span>
        </slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="view-title__content pt-8">
        <view-item
          v-for="item in filteredChildrens"
          :key="item.prop"
          :prop-path="getPropertyPath(item)"
          v-bind="item"
        />
        <div v-if="info.show" class="view-card-section__info">
          <v-icon left :color="info.colorIcon">
            {{ info.icon }}
          </v-icon>
          <span class="view-card-section__text-ifo">{{ info.text }}</span>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <div v-else class="view-card-section">
    <div class="view-card-section__header">
      <h3 class="view-card-section__title">
        {{ label }}
      </h3>
      <div v-if="info.show" class="view-card-section__info">
        <span class="view-card-section__text-ifo">{{ info.text }}</span>
        <v-icon right :color="info.colorIcon">
          {{ info.icon }}
        </v-icon>
      </div>
    </div>
    <div v-for="item in filteredChildrens" :key="item.prop" :class="`view-flex-row__item-${item.prop || item.type}`">
      <view-item v-bind="item" :prop-path="getPropertyPath(item)">
        {{ item }}
      </view-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemaViewCardSection',
  components: {
    ViewItem: () => import('./ViewItem.vue'),
  },
  inject: {
    media: 'media',
    mustDisplaySchemaItem: 'mustDisplaySchemaItem',
  },
  props: {
    label: { type: String, required: true },
    propPath: { type: Array, required: true },
    children: { type: Array, default: () => [] },
    value: {
      type: Object,
      default: () => ({
        info: {
          show: false,
          colorIcon: '',
          text: '',
          icon: '',
        },
      }),
    },
  },
  computed: {
    filteredChildrens() {
      return this.children.filter(item => this.mustDisplaySchemaItem(item, [...this.propPath, item.prop]));
    },
    info() {
      return this.value.info;
    },
  },
  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>

<style lang="scss">
.view-card-section {
  border: 1px solid #c7d6ff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;

  &__header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: $--font-weight-medium;
    font-size: 24px;
    line-height: 32px;
  }

  &__text-ifo {
    font-weight: $--font-weight-normal;
    font-size: 12px;
    line-height: 20px;
    color: $--grey-color-0;
  }
}
</style>
