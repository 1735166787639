export default function joinWithLimit(array, limit = 3) {
  if (!array || !Array.isArray(array)) return;
  const elements = array.map(el => (el.name ? el.name : el));
  if (elements.length > limit) {
    const moreElements = elements.length - limit;
    const showElements = elements.slice(0, limit);
    // eslint-disable-next-line consistent-return
    return `${showElements.join(', ')} (+${moreElements})`;
  }

  // eslint-disable-next-line consistent-return
  return elements.join(', ');
}
