<template>
  <v-menu v-model="menuIsOpen" offset-y content-class="view-history">
    <template #activator="{ on, attrs }">
      <v-btn class="elevation-0 primary--text" color="secondary" v-bind="attrs" v-on="on">
        {{ label }}
      </v-btn>
    </template>

    <div v-if="history.isLoading" class="view-history__loader">
      <base-loader />
    </div>

    <v-list v-else class="py-6">
      <div v-if="historySize === 0" class="d-flex align-center justify-center">
        {{ $t('unit.no_history_yet') }}
      </div>

      <template v-for="(item, index) in history.list">
        <v-list-item :key="index" class="view-history__item px-6">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="view-history__field">
              <view-field :label="getDateTitle(item)">
                {{ getDate(item) }}
              </view-field>
            </v-col>

            <v-col cols="12" md="6" class="view-history__field">
              <view-field :label="$t('unit.client')">
                <router-link :to="getClientLink(item.client)">{{ item.firstName }} {{ item.lastName }}</router-link>
              </view-field>
            </v-col>
          </v-row>
        </v-list-item>

        <v-divider
          v-if="media.isMobile && index !== historySize - 1"
          :key="`divider-${item.client}`"
          class="my-6 mx-6"
        />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
// Node_modules
import { format } from 'date-fns';

// Utils
import { translateDate } from '@/utils/dateFormatting';

// Constants
import { CLIENTS_DETAILED } from '@/constants/routes';
import { RENTAL_HISTORY_VIEWING } from '@/constants/analyticsActions';

// Services
import realtyService from '@/services/realty';
import analyticsService from '@/services/analytics';

// Components
import ViewField from '@/components/ViewField.vue';
import BaseLoader from '@/components/BaseLoader.vue';

export default {
  name: 'ViewHistory',

  components: { ViewField, BaseLoader },

  inject: ['media'],

  props: {
    label: { type: String, required: true },
    payload: { type: Object, required: true },
  },

  data() {
    return {
      history: {
        list: [],
        isLoading: false,
      },

      menuIsOpen: false,
    };
  },

  computed: {
    unitId() {
      return this.payload.id;
    },

    historySize() {
      return this.history.list.length;
    },
  },

  watch: {
    menuIsOpen(newValue) {
      if (newValue) {
        analyticsService.track(RENTAL_HISTORY_VIEWING);
      }
      if (newValue && this.historySize === 0) {
        this.fetchHistory();
      }
    },
  },

  methods: {
    getClientLink(clientId) {
      return { name: CLIENTS_DETAILED, params: { id: clientId } };
    },

    fetchHistory() {
      if (this.history.isLoading) {
        return;
      }

      this.history.isLoading = true;

      realtyService
        .getUnitHistory(this.unitId)
        .then(unitHistory => {
          this.history.list = unitHistory.usages;
        })
        .finally(() => {
          this.history.isLoading = false;
        });
    },

    getDate(item) {
      if (item.salesContractDate) {
        const startDate = new Date(item.salesContractDate);
        const endDate = item.archivedAt ? new Date(item.archivedAt) : undefined;

        if (endDate) {
          return `${translateDate(format(startDate, 'd L yyyy'))} - ${translateDate(format(endDate, 'd L yyyy'))}`;
        }

        return translateDate(format(startDate, 'd L yyyy'));
      }

      if (item.rentalPeriodEndDate && item.rentalPeriodStartDate) {
        const startDate = new Date(item.rentalPeriodStartDate);
        const endDate = new Date(item.rentalPeriodEndDate);

        return `${translateDate(format(startDate, 'd L yyyy'))} - ${translateDate(format(endDate, 'd L yyyy'))}`;
      }

      return '-';
    },

    getDateTitle(item) {
      if (item.clientType === 'owner') {
        return this.$t('client.sales_contract_date');
      }

      return this.$t('client.rent');
    },
  },
};
</script>

<style lang="scss">
.view-history {
  min-width: 560px !important;
  max-height: 424px !important;

  @media (max-width: map.get($--screens, 'sm')) {
    min-width: calc(100% - 16px) !important;
    transform: translateX(4px);
    max-height: 360px !important;
  }

  &__item:not(:first-child) {
    margin-top: 24px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      margin-top: 0px !important;
    }
  }

  &__field:not(:first-child) {
    margin-top: 0px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      margin-top: 24px !important;
    }
  }

  &__loader {
    position: relative;
    min-height: 72px;
  }
}
</style>
