<template>
  <view-field class="font-weight-medium" :label="label">
    <a :href="value" class="black--text text-decoration-underline">{{ value }}</a>
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldLink',

  components: { ViewField },

  props: {
    value: { type: String, default: '' },
    label: { type: String, required: true },
  },
};
</script>
