<template>
  <div class="view-task-status-change">
    <p class="view-task-status-change__status-text">
      <span>{{ $t('task.change_status_history') }} </span>
      <span class="view-task-status-change__status">{{ $t(`tasks.${value.oldStatus}`) }}</span>
      {{ $t('task.status_to') }}
      <span class="view-task-status-change__status">{{ $t(`tasks.${value.newStatus}`) }}</span>
    </p>
    <p class="view-task-status-change__comment">
      {{ value.comment }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ViewTaskStatusChange',
  props: {
    value: { type: Object, default: () => {} },
    label: { type: String, required: true },
  },
};
</script>

<style lang="scss">
.view-task-status-change {
  margin-top: 8px;
  &__status-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: $--black-color-0;
    opacity: 0.9;
  }
  &__status {
    color: $--blue-color-20;
  }
  &__comment {
    margin-top: 32px;
    font-style: italic;
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: $--black-color-0;
    opacity: 0.6;
  }
}
</style>
