<template>
  <view-field class="font-weight-medium" :label="label">
    <view-image-item
      v-for="({ id, url }, index) in trimItems"
      :key="id"
      class="mr-3 mb-3"
      :image-url="url"
      @click="openSlider(index)"
    />

    <v-btn
      v-if="isShowMore"
      class="elevation-0 primary--text text-h5 font-weight-bold"
      color="secondary"
      height="154"
      width="154"
      @click="showMore"
    >
      +{{ numberOfHiddenItems }}
    </v-btn>

    <photo-slider ref="slider" v-model="currentSlide" :images="imagesForSlider" :title="payload.listTitle" />
  </view-field>
</template>

<script>
// Services
import mediaService from '@/services/media';

// Utils
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import PhotoSlider from '@/components/PhotoSlider.vue';
import ViewField from '@/components/ViewField.vue';
import ViewImageItem from './ViewImageItem.vue';

export default {
  name: 'SchemaViewImageList',

  components: { ViewField, ViewImageItem, PhotoSlider },

  props: {
    label: { type: String, default: null },
    value: { type: [Array, String], default: () => [] },
    payload: { type: Object, default: () => ({}) },
    onlyOne: { type: Boolean, default: false },
  },

  data() {
    return {
      isTrim: true,
      localValue: [],
      currentSlide: 0,
      imagesForSlider: [],
    };
  },

  computed: {
    trimItems() {
      if (this.isTrim) {
        return this.localValue.slice(0, 4);
      }

      return this.localValue;
    },

    numberOfHiddenItems() {
      if (this.localValue.length > 4) {
        return this.localValue.length - 4;
      }

      return 0;
    },

    isShowMore() {
      return this.localValue.length > 4 && this.isTrim;
    },
  },

  watch: {
    value: {
      async handler(images) {
        if (this.onlyOne) {
          const newImage = {
            url: await this.getPrivateImage(images),
          };

          this.localValue = [newImage];
          this.imagesForSlider = [newImage.url];

          return;
        }
        const result = await Promise.all(
          images.map(async image => {
            const newImage = { ...image };
            newImage.url = await this.getPrivateImage(newImage.url);

            return newImage;
          })
        );

        this.localValue = result;
        this.imagesForSlider = result.map(image => image.url);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    showMore() {
      this.isTrim = false;
    },

    getPrivateImage(url) {
      return mediaService.getPrivateMedia(getBaseUrlWithoutApi() + url);
    },

    openSlider(index) {
      this.currentSlide = index;
      this.$refs.slider.open();
    },
  },
};
</script>
