<template>
  <div class="view-row mb-8" :class="{ 'view-row--with-line': showLine }">
    <v-row>
      <v-col
        v-for="item in filteredChildrens"
        :key="item.prop"
        cols="12"
        :md="getItemSize('md', item)"
        :xl="getItemSize('xl', item)"
      >
        <view-item v-bind="item" :prop-path="getPropertyPath(item)">
          {{ item }}
        </view-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SchemaViewRow',

  components: {
    ViewItem: () => import('./ViewItem.vue'), // to register the component correctly
  },

  inheritAttrs: false,

  inject: {
    mustDisplaySchemaItem: 'mustDisplaySchemaItem',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
    },
    fill: {
      type: Boolean,
      default: false,
    },
    showLine: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    filteredChildrens() {
      return this.children.filter(item => this.mustDisplaySchemaItem(item, [...this.propPath, item.prop]));
    },

    size() {
      if (this.fill) {
        return 6;
      }

      if (this.children.length <= 2) {
        return 4;
      }

      if (this.children.length === 4) {
        return 3;
      }

      return 2;
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },

    getItemSize(breakPoint, item) {
      if (item?.size && typeof item?.size === 'number') {
        return item?.size || this.size;
      }

      return item?.size?.[breakPoint] || this.size;
    },
  },
};
</script>

<style lang="scss">
.view-row {
  &--with-line {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 47, 255, 0.1);
  }
}
</style>
