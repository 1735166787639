<template>
  <view-field class="font-weight-medium" :label="label"> {{ value }} {{ payload.currencySymbol }} </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldAmount',

  components: { ViewField },

  props: {
    value: { type: Number, default: 0 },
    label: { type: String, required: true },
    payload: { type: Object, default: () => ({}) },
  },
};
</script>
