<template>
  <div class="tasks-activities">
    <activities-form class="tasks-activities__form mb-14" :issue-id="issueId" @create-activity="createActivity" />
    <activities-view
      :issue-id="issueId"
      :activities="taskActivities"
      :status="status"
      :is-loading="isLoading"
      @change-status="changeStatus"
    />
  </div>
</template>

<script>
// Components
import ActivitiesForm from '@/components/Tasks/ActivitiesForm.vue';
import ActivitiesView from '@/components/Tasks/ActivitiesView.vue';

// Services
import issueService from '@/services/issues';

export default {
  name: 'SchemaViewTaskActivities',

  components: { ActivitiesForm, ActivitiesView },

  data() {
    return {
      isLoading: false,
      issueId: this.$route.params.id,
      limit: 100,
      offset: 0,
      taskActivities: [],
      status: 'all',
    };
  },

  computed: {},

  mounted() {
    this.isLoading = true;
    this.getActivities()
      .then(({ results }) => {
        this.taskActivities = results;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    createActivity() {
      if (this.status !== 'status') {
        this.getActivities({ limit: 1, offset: 0 }).then(({ results }) => {
          this.taskActivities.unshift(results[0]);
        });
      }
    },

    async getActivities(params) {
      const limit = params?.limit || this.limit;
      const offset = params?.offset || this.offset;

      if (this.status === 'status') {
        const { results: statusResult } = await issueService.getActivities({
          issue: this.issueId,
          action: 'status',
          orderBy: '-created_at',
          limit,
          offset,
        });

        const { results: createResult } = await issueService.getActivities({
          issue: this.issueId,
          action: 'create',
          orderBy: '-created_at',
          limit,
          offset,
        });

        return { results: [...statusResult, ...createResult] };
      }

      return issueService.getActivities({
        issue: this.issueId,
        action: this.status === 'all' ? undefined : this.status,
        orderBy: '-created_at',
        limit,
        offset,
      });
    },

    changeStatus(status) {
      this.isLoading = true;
      this.status = status;
      this.offset = 0;
      this.getActivities()
        .then(({ results }) => {
          this.taskActivities = results;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<stylee lang="scss">
.tasks-activities {
  max-width: 548px;

  &__input {
    position: relative;

    .v-input__slot {
      padding-bottom: 48px !important;
    }

    .test {
      position: absolute;
      right: 12px;
      bottom: 10px;
    }
  }
}
</stylee>
