<template>
  <view-field class="font-weight-medium" :label="label">
    {{ currentValue }}
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';
import { separateThouthands } from '@/utils/priceFormatting';

export default {
  name: 'SchemaViewFieldCoast',

  components: { ViewField },

  props: {
    value: { type: String, default: '' },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      if (this.value) {
        return `${separateThouthands(this.value)} ${this.$t('invoice.euro')}`;
      }
      return '—';
    },
  },
};
</script>
