<template>
  <view-field class="font-weight-medium" :label="label">
    {{ value }}
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldNumber',

  components: { ViewField },

  props: {
    value: { type: Number, default: 0 },
    label: { type: String, required: true },
  },
};
</script>
