<template>
  <view-field class="font-weight-medium" :label="label">
    {{ currentValue }}
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldString',

  components: { ViewField },

  props: {
    value: { type: Boolean, default: false },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      return this.$t(this.value ? 'boolean.have' : 'boolean.not_have');
    },
  },
};
</script>
