<template>
  <view-field class="font-weight-medium" :label="label">
    {{ $t(localValue) }}<sup v-if="isAreaUnit">2</sup>
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldEnum',

  components: { ViewField },

  props: {
    value: { type: [Number, String, Object], default: '' },
    label: { type: String, required: true },
    isAreaUnit: { type: Boolean, default: false },
  },

  computed: {
    localValue() {
      if (typeof this.value === 'string') {
        switch (this.value) {
          case 'ft':
            return 'si.ft';
          case 'm2':
            return 'si.m';
          default:
            return '';
        }
      }
      return this.value?.name || '';
    },
  },
};
</script>
