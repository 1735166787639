<template>
  <div>
    <div v-if="showLabel" class="text-body-1 mb-2 text--secondary">
      <slot name="label">{{ label }}</slot>
    </div>
    <div class="d-flex flex-wrap mr-n3">
      <view-file-item
        v-for="{ id, url, type, name, size } in trimItems"
        :key="id"
        class="mr-3 mb-3"
        :url="url"
        :type="type"
        :name="name"
        :size="size"
        :show-row="showRow"
        @click="openSlider(url)"
      />

      <v-btn
        v-if="isShowMore"
        class="elevation-0 primary--text text-h5 font-weight-bold"
        color="secondary"
        height="80"
        width="80"
        @click="showMore"
      >
        +{{ numberOfHiddenItems }}
      </v-btn>

      <photo-slider v-if="!hiddenSlider" ref="slider" v-model="currentSlide" :images="imagesForSlider" :title="title" />
    </div>
  </div>
</template>

<script>
// Services
import mediaService from '@/services/media';

// Utils
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import PhotoSlider from '@/components/PhotoSlider.vue';
import ViewFileItem from './ViewFileItem.vue';

export default {
  name: 'SchemaViewFileList',

  components: { ViewFileItem, PhotoSlider },

  props: {
    hiddenSlider: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    payload: { type: Object, default: () => ({}) },
    label: { type: String, default: '' },
    showLabel: { type: Boolean, default: false },
    showRow: { type: Boolean, default: false },
  },

  data() {
    return {
      isTrim: true,
      localValue: [],
      currentSlide: 0,
    };
  },

  computed: {
    trimItems() {
      if (this.isTrim) {
        return this.localValue.slice(0, 4);
      }
      return this.localValue;
    },

    numberOfHiddenItems() {
      if (this.localValue.length > 4) {
        return this.localValue.length - 4;
      }

      return 0;
    },

    imagesForSlider() {
      return this.localValue.filter(file => this.fileIsImage(file.type)).map(image => image.url);
    },

    isShowMore() {
      return this.localValue.length > 4 && this.isTrim;
    },
    title() {
      return this.payload?.listTitle || this.label;
    },
  },

  watch: {
    value: {
      async handler(files) {
        const result = await Promise.all(
          files.map(async file => {
            const newFile = { ...file };
            newFile.name = decodeURIComponent(newFile.url)
              .split('/')
              .pop();

            const { url, blob } = await this.getPrivateMediaObject(newFile.url);

            newFile.url = url;
            newFile.type = blob.type;
            newFile.size = blob.size;
            return newFile;
          })
        );
        this.localValue = result;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    showMore() {
      this.isTrim = false;
    },

    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },

    openSlider(url) {
      if (!this.hiddenSlider) {
        const indexImage = this.imagesForSlider.findIndex(img => img === url);
        this.currentSlide = indexImage;
        this.$refs.slider.open();
      }
    },

    fileIsImage(type) {
      return type.startsWith('image/');
    },
  },
};
</script>
