<template>
  <v-expansion-panels class="view-expansion-section" flat>
    <v-expansion-panel>
      <v-expansion-panel-header
        :hide-actions="true"
        class="view-expansion-section__btn elevation-0 primary--text"
        color="secondary"
        @click="toggle"
      >
        <slot name="label">
          {{ label }}
          <arrow-up class="view-expansion-section-btn__icon" :class="classBtnIcon" />
        </slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="view-expansion-section__content pt-8">
        <view-item
          v-for="item in filteredChildrens"
          :key="item.prop"
          :prop-path="getPropertyPath(item)"
          v-bind="item"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ArrowUp from '@/components/Icons/ArrowUp.vue';

export default {
  name: 'SchemaViewExpansionSection',
  components: {
    ArrowUp,
    ViewItem: () => import('./ViewItem.vue'),
  },
  inject: {
    mustDisplaySchemaItem: 'mustDisplaySchemaItem',
  },
  props: {
    label: { type: String, required: true },
    propPath: { type: Array, required: true },
    children: { type: Array, default: () => [] },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    filteredChildrens() {
      return this.children.filter(item => this.mustDisplaySchemaItem(item, [...this.propPath, item.prop]));
    },
    classBtnIcon() {
      return { 'view-expansion-section-btn__icon_rotation-180': !this.isOpen };
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>

<style lang="scss">
.view-expansion-section {
  &__btn {
    width: auto;
    height: 44px;
    min-height: 0 !important;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.00892857em;
    text-transform: uppercase;
  }
  &__content {
    margin: 0 -24px;
    opacity: 0.6;
  }
}
.view-expansion-section-btn {
  &__icon {
    transition: all 0.5s ease-out;
    margin-left: 10px;
    &_rotation-180 {
      transform: rotate(180deg);
      transition: all 0.5s ease-out;
    }
  }
}
</style>
