<template>
  <div class="view-expansion-panel">
    <h3 v-if="label" class="text-body-1 mb-2 text--secondary">
      {{ label }} <span v-if="showCount">({{ value.length }})</span>
    </h3>
    <v-expansion-panels accordion :readonly="isOnlyHeader" flat>
      <v-expansion-panel>
        <v-expansion-panel-header
          :hide-actions="isOnlyHeader"
          class="view-expansion-panel__header"
          expand-icon="mdi-menu-down"
        >
          <slot name="label">
            <span
              ref="expansionPanelHeaderText"
              class="view-expansion-panel__header-text"
              :class="{ 'view-expansion-panel__header-text--text': text }"
              >{{ textHeader }}</span
            >
          </slot>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="view-expansion-panel__content"
          :class="{ 'view-expansion-panel__content--text': text }"
        >
          {{ textBody }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import getTextWidth from '@/utils/text';

const ITEM_SEPARATOR = ', ';

export default {
  name: 'ViewExpansionPanel',
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      widthHeaderPanel: 0,
    };
  },

  computed: {
    lastHeaderItemIndex() {
      let lastIndex = 0;
      let headerText = '';
      while (lastIndex < this.value.length) {
        const itemText = this.value[lastIndex];
        let newHeaderText = headerText;
        newHeaderText += lastIndex === 0 ? itemText : `${ITEM_SEPARATOR} ${itemText}`;
        const font = this.text ? '16px Roboto' : '12px Roboto';
        const headerTextWidth = this.getTextWidth(newHeaderText, font);
        if (headerTextWidth < this.widthHeaderPanel) {
          headerText = newHeaderText;
          lastIndex += 1;
        }
        if (headerTextWidth > this.widthHeaderPanel || lastIndex === this.value.length) {
          lastIndex -= 1;
          break;
        }
      }

      return lastIndex;
    },

    textHeader() {
      if (this.lastHeaderItemIndex) {
        let textHeader = `${this.value.slice(0, this.lastHeaderItemIndex + 1).join(', ')},`;
        if (this.isOnlyHeader) {
          textHeader = `${this.value.slice().join(', ')}`;
        }
        return textHeader;
      }
      return this.value.join(', ');
    },
    textBody() {
      if (!this.isOnlyHeader) {
        return `${this.value.slice(this.lastHeaderItemIndex + 1).join(', ')}`;
      }
      return '';
    },
    isOnlyHeader() {
      return this.lastHeaderItemIndex + 1 === this.value.length;
    },
  },

  mounted() {
    this.initWidthHeaderPanel();
    const observer = new ResizeObserver(() => {
      this.initWidthHeaderPanel();
    });
    observer.observe(this.$refs.expansionPanelHeaderText);
    this.$options.unobserve = () => {
      observer.unobserve(this.$refs.expansionPanelHeaderText);
    };
  },

  beforeDestroy() {
    this.$options.unobserve();
  },

  methods: {
    getTextWidth,
    initWidthHeaderPanel() {
      if (this.$refs.expansionPanelHeaderText) {
        this.widthHeaderPanel = this.$refs.expansionPanelHeaderText.clientWidth;
      }
    },
  },
  unobserve: null,
};
</script>

<style lang="scss">
.view-expansion-panel {
  max-width: 660px;
  &__header-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #1e1e22;
    opacity: 0.5;
    &--text {
      font-weight: $--font-weight-medium;
      font-size: 16px;
      line-height: 24px;
      color: $--black-color-0;
      opacity: 1;
    }
  }
  &__content {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #1e1e22;
    opacity: 0.5;
    &--text {
      font-weight: $--font-weight-medium;
      font-size: 16px;
      line-height: 24px;
      color: $--black-color-0;
      opacity: 1;
    }
  }
  .v-expansion-panel-header {
    padding: 0;
    min-height: 0 !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
