import { render, staticRenderFns } from "./ViewManyProjects.vue?vue&type=template&id=cd7295d2"
import script from "./ViewManyProjects.vue?vue&type=script&lang=js"
export * from "./ViewManyProjects.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports