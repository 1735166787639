<template>
  <view-field class="font-weight-medium" :label="label">
    <p class="mb-1">{{ joinWithLimit(value) }}</p>
    <p v-if="employees.length" class="text-body-2 text--secondary">{{ joinWithLimit(employees) }}</p>
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

import joinWithLimit from '@/utils/joinWithLimit';

export default {
  name: 'SchemaViewFieldManyRoles',

  components: { ViewField },

  props: {
    value: { type: [Array, Object], default: () => [] },
    label: { type: String, required: true },
  },

  computed: {
    employees() {
      if (!this.value || !Array.isArray(this.value)) return [];
      const employees = [];
      this.value.forEach(item => employees.push(...item.employees));
      return employees.map(employee => ({ id: employee.id, name: `${employee.firstName} ${employee.lastName}` }));
    },
  },

  methods: {
    joinWithLimit,
  },
};
</script>
