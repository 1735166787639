<template>
  <div>
    <div class="tasks-activities__input mb-5">
      <FormFieldString
        v-model="comment"
        textarea
        :payload="{}"
        hide-details
        auto-grow
        :label="$t('tasks.activities_field_label')"
      />
      <div class="test d-flex">
        <FileInput multiple @input="addNewImage">
          <template #default="{ on, attrs }">
            <icon-button exact :width="36" color="#F5F5F5" :disables="sending" v-bind="attrs" v-on="on">
              <IconAttachment width="20" fill="#9299A2" />
            </icon-button>
          </template>
        </FileInput>
        <icon-button class="ms-2" exact :width="36" color="primary" :loading="sending" @click="addComment">
          <IconSend width="24" fill="white" />
        </icon-button>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <FileItem
        v-for="({ tempId, url, privateUrl, isLoading, size, name, type, id, selected }, index) in files"
        :key="tempId"
        class="mr-3 mb-3"
        :url="url"
        :private-url="privateUrl"
        :loading="isLoading"
        :editable="true"
        :size="size"
        :name="name"
        :type="type"
        :show-row="true"
        :show-info="false"
        @remove="removeItem(index)"
        @click="onItemClick(tempId || id)"
      />
      <photo-slider ref="slider" v-model="currentSlide" :images="imagesForSlider" />
    </div>
  </div>
</template>

<script>
import { getUuid } from '@/utils/generators';

import IconButton from '@/components/IconButton.vue';
import FileInput from '@/components/FileInput.vue';
import PhotoSlider from '@/components/PhotoSlider.vue';

import IconSend from '@/assets/icons/Send.svg';
import IconAttachment from '@/assets/icons/Attachment2.svg';
import mediaService from '@/services/media';
import issueService from '@/services/issues';
import FileItem from '@/components/schema/FormFileItem.vue';
import FormFieldString from '@/components/schema/FormFieldString.vue';

export default {
  name: 'ActivitiesForm',

  components: { IconButton, FileInput, FileItem, PhotoSlider, IconSend, IconAttachment, FormFieldString },

  props: {
    issueId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      sending: false,
      maxFileNumbers: 10,
      currentSlide: 0,
      comment: '',
      files: [],
    };
  },

  computed: {
    imagesForSlider() {
      return this.files ? this.files.filter(file => this.fileIsImage(file.type)).map(image => image.url) : [];
    },
  },

  mounted() {},

  methods: {
    addComment() {
      this.sending = true;
      issueService
        .addComment({
          issueId: this.issueId,
          comment: this.comment,
          files: this.files.map(file => ({ backgroundFileId: file.backgroundFileId })),
        })
        .then(() => {
          this.comment = '';
          this.files = [];
          this.sending = false;
          this.$emit('create-activity');
        });
    },

    addNewImage(files) {
      const filesArray = Array.from(files);

      filesArray.slice(0, Math.max(0, this.maxFileNumbers - this.files.length)).forEach(file => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.addEventListener('load', async fileUrl => {
          const tempId = getUuid();
          const length = this.addItem({
            url: fileUrl.target.result,
            tempId,
            isLoading: true,
            type: file.type,
            size: file.size,
            name: file.name,
          });
          if (!length) return;

          mediaService
            .uploadFile(file)
            .then(tempFileData => {
              const index = this.findFileIndex(tempId);
              if (index === -1) return;

              const originFilesList = this.files.slice().map(item => ({ ...item, selected: false }));

              const newFields = {
                backgroundFileId: tempFileData.id,
                isLoading: false,
                privateUrl: tempFileData.url,
              };

              originFilesList.splice(index, 1, { ...originFilesList[index], ...newFields });

              this.files = originFilesList;
            })
            .catch(() => {
              const index = this.findFileIndex(tempId);
              if (index === -1) {
                return;
              }
              this.removeItem(index);
            });
        });
      });
    },

    removeItem(index) {
      const newValue = this.files.slice();
      newValue.splice(index, 1);
      this.files = newValue;
    },

    addItem(item) {
      const files = [...this.files, item];
      this.files = files;
      return files.length;
    },

    findFileIndex(tempId) {
      return this.files.findIndex(item => item.tempId === tempId);
    },

    fileIsImage(type) {
      return type ? type.startsWith('image/') : false;
    },

    onItemClick(id) {
      if (!this.hiddenSlider) {
        const indexImage = this.files.findIndex(img => img.id === id || img.tempId === id);
        this.openSlider(indexImage);
      }
    },

    openSlider(index) {
      this.currentSlide = index;
      if (this.$refs?.slider.open) {
        this.$refs.slider.open();
      }
    },
  },
};
</script>
