<template>
  <div class="base-loader d-flex align-center justify-center">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>

<script>
export default {
  name: 'BaseLoader',
};
</script>

<style lang="scss">
.base-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: $--white-color-0;
}
</style>
