<template>
  <v-expansion-panels v-if="media.isMobile" class="view-title" flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="view-title__header">
        <slot name="label">
          <span class="text-h6 font-weight-medium mt-4 mb-4">{{ label }}</span>
        </slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="view-title__content pt-8">
        <view-item
          v-for="item in filteredChildrens"
          :key="item.prop"
          :prop-path="getPropertyPath(item)"
          v-bind="item"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <span v-else>
    <view-title :remove-m-t="removeMT">
      <slot name="label">
        {{ label }}
      </slot>
    </view-title>
    <view-item v-for="item in filteredChildrens" :key="item.prop" :prop-path="getPropertyPath(item)" v-bind="item" />
  </span>
</template>

<script>
export default {
  name: 'SchemaViewTitledSection',

  components: {
    ViewItem: () => import('./ViewItem.vue'), // to register the component correctly
    ViewTitle: () => import('./ViewTitle.vue'),
  },

  inheritAttrs: false,

  inject: {
    media: 'media',
    mustDisplaySchemaItem: 'mustDisplaySchemaItem',
  },

  props: {
    label: { type: String, default: '' },
    propPath: { type: Array, required: true },
    children: { type: Array, default: () => [] },
    removeMT: { type: Boolean, default: false },
  },

  computed: {
    filteredChildrens() {
      return this.children.filter(item => this.mustDisplaySchemaItem(item, [...this.propPath, item.prop]));
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>

<style lang="scss">
.view-title {
  &__header {
    border-bottom: 1px solid rgba(0, 47, 255, 0.15);
    padding: 0 !important;
  }

  &__content {
    .v-expansion-panel-content__wrap {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
