<template>
  <view-field v-if="!value" class="font-weight-medium" label="label.value">
    {{ $t('no') }}
  </view-field>
  <div v-else>
    <h3 class="text-subtitle-1 font-weight-bold">{{ $t('requests.payment_required') }}</h3>

    <v-divider class="schema-view-field-contact-container__divider" />

    <v-row>
      <v-col size="12" md="6">
        <view-field class="font-weight-medium" label="label.amount">
          {{ value.amount }}
        </view-field>
      </v-col>
      <v-col size="12" md="6">
        <view-field class="font-weight-medium" label="label.contractor">
          {{ value.contractor.name }}
        </view-field>
      </v-col>
    </v-row>

    <v-divider class="schema-view-field-contact-container__divider" />
  </div>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewRequestPayment',

  components: { ViewField },

  props: {
    value: { type: [Object, Boolean], default: false },
  },
};
</script>
